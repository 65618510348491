.grid-action-btn {
    padding: 5px 7px !important;
}

/** Allow for ag-menu (column menues) to be overflowable*/
.ag-theme-quartz .ag-menu {
    overflow: visible ;
}

/** Needed so SelectFilter's Semantic UI Dropdown can overflow .ag-menu*/
.ag-simple-filter-body-wrapper.allow-overflow {
    overflow: unset
}
